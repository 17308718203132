<div [ngSwitch]="displayPermission">
  <div *ngSwitchCase="true">
    <div class="firm-security-setting-header">
      <div class="form-inline margin-left-05" id="securitypref">
        <div class="form-group">
          <label class="search-label-pref">Search Securities: </label>
          <div class="portfoliodetails-search-add">
            <eclipse-security-autocomplete [filterOutSecurityIds]="filterOutSecurityIds" (onSearchSecuritySelect)="searchSecurity($event)"></eclipse-security-autocomplete>
          </div>
          <button type="button" pButton title="Set Preferences" (click)="setPrefPopup()" class="m-l-10" style="vertical-align: top;"
                  [disabled]="btnDisableSetPref" label="Set Preferences">
          </button>
        </div>

        <a href="javascript:void(0)" class="btn-reset pull-right">
          <i class="fas fa-undo" id="securityPreferenceReset" (click)='displayConfirm=true' aria-hidden="true"></i>
        </a>
        <p-dialog id="securityPreferenceConfirmDialog" header="" [(visible)]="displayConfirm" modal="true"
                  [style]="{'width': '720px'}">
          <p class="text-center">Resetting will remove ALL Security Preferences from this level.</p>
          <p class="text-center">Do you wish to proceed? </p>
          <p-footer>
            <button type="button" pButton icon="fas fa-check" (click)="setResetControl(); displayConfirm=false" label="Yes"></button>
            <button type="button" pButton icon="fas fa-times" (click)="displayConfirm=false" class="p-button-secondary" label="No"></button>
          </p-footer>
        </p-dialog>
      </div>
    </div>
  </div>
  <div *ngIf="showPageContent" class="agcontainer">
    <div *ngSwitchCase="true">
      <ag-grid-angular #agGrid style="width:100%;height:400px;" class="ag-theme-alpine grid-height-autosizefive"
                       [gridOptions]="gridOptions" [context]="gridContext" (gridReady)="onGridReady($event)"
                       rowSelection="single" [columnDefs]="columnDefs"
                       [rowData]="preferenceRowData"
                       (modelUpdated)="onModelUpdated()"
                       [getContextMenuItems]="getContextMenuItems"></ag-grid-angular>
    </div>
    <div *ngSwitchCase="false">
      <ag-grid-angular class="ag-theme-alpine grid-height-autosizefive" #agGrid style="width: 100%;height:400px;"
                       [columnDefs]="columnDefs" [rowData]="preferenceRowData" (gridReady)="onGridReady($event)"
                       [gridOptions]="gridOptions" [suppressContextMenu]="true"></ag-grid-angular>
    </div>
  </div>

  <eclipse-security-preference-add-edit
    [preferenceLevel]="levelName"
    [securityPreferencesHiddenMap]="securityPreferencesHiddenMap"
    (addPreferenceToGrid)="addToGrid($event)">
  </eclipse-security-preference-add-edit>

</div>

<p-dialog header="Security Preferences" [resizable]="true" [(visible)]="displayEquivalentDialog"
          [style]="{'width': '1050px'}" (onHide)="cancel()">

  <eclipse-security-preference-equivalent (onEquivalentUpdate)="addToGrid($event)"></eclipse-security-preference-equivalent>
  <p-footer>
    <button type="button" icon="fas fa-times" pButton class="p-button-secondary" (click)="cancel()" label="Cancel"></button>
    <button type="button" icon="fas fa-plus" pButton (click)="addEquivalent()" label="Add"></button>
  </p-footer>
</p-dialog>

<p-dialog header="" [(visible)]="displayWarningMessage" modal="modal" class="ui-dialog-md">
  <div>
    <div> This security is an equivalent, deleting will also remove it from equivalents. Do you want to continue? </div>
    <footer class="p-footer text-right">
      <div class="ui-dialog-buttonpane ui-helper-clearfix">
        <button type="button" icon="fa-times" (click)="displayWarningMessage=false;deletedSecurityPreferenceId=null" class="btn btn-info btn-raised">No</button>
        <button type="button" icon="fa-check" (click)="deleteSecurityPreferenceAndEquivalents()" class="btn btn-blueouter">Yes</button>
      </div>
    </footer>
  </div>
</p-dialog>
