export enum SubModelTypeEnum {
  CATEGORY = 1,
  CLASS = 2,
  SUBCLASS = 3,
  SECURITYSET = 4
}

export enum ModelSubModelTypes {
  Category = 1,
  Class = 2,
  SubClass = 3,
  SecuritySet = 4
}

export enum SubModelName {
  Category = 'Category',
  Class = 'Class',
  SubClass = 'SubClass',
  SecuritySet = 'Security Set',
  Security_Set = 'SECURITY_SET',
  Security = 'Security'
}
