import { IAuditHistoryChanges } from './audit-history';

export interface IAccount {
  id: number;
  name: string;
  accountName: string;
  accountNumber: string;
  accountId: string;
  accountType: string; // deprecated in dynamic account list
  accountTypeName: string;
  portfolio: string;
  custodian: string;
  value: number;
  managedValue: number;
  excludedValue: number;
  pendingValue: number;
  washSaleGroup: string;
  style: string;
  model: string;
  sleeveType: string;
  distributionAmount: number;
  contributionAmount: number;
  mergeIn: number;
  mergeOut: number;
  cashNeedAmount: number;
  targetCashReserve: number;
  systematicAmount: number;
  systematicDate: Date;
  sma: string;
  status: string;
  pendingTrades: string;
  reason: string; // deprecated
  statusReason: string;
  eclipseCreatedDate: Date;
  reserveCashActual: number;
  reserveCashTarget: number;
  setAsideCashTarget: number;
  setAsideCashActual: number;
  targetCash: number;
  targetCashPercent: number;
  currentCash: number;
  currentCashPercent: number;
  pendingCashValue: number;
  pendingCashPercent: number;
  cashNeedPercent: number;
  pendingValuePercent: number;
  isDisabled: number;
  needAnalyticsStatus: string;
  portfolioId: number;
  createdOn: Date;
  createdBy: number;
  editedOn: Date;
  editedBy: string;
  astroEnabled: boolean;
  astroTemplateId: string;
  portfolioTolerance: string;
  portfolioCashTolerance: string;
  tradingInstructions: string;
  isOptionTradingAllowed: boolean;
  sleeveStrategyId: string;
  sleeveStrategyName: string;
  sleeveStrategyAggregateId: string;
  sleeveStrategyAggregateName: string;
  lastRebalancedOn: Date;
  lastTradedDate: Date;
  registrationId: string;
  portfolioDoNotTrade: boolean;
  orionConnectExternalId: number;
  minSetAsideCash: number;
  maxSetAsideCash: number;
  tags: string;
  doNotTrade: string;
  isDiscretionary: string;
  managementStyle: string;
  accountTags: string;
  representativeNumber: string;
  householdName: string;
  orionConnectFirmId: number;
  outsideId: string;
  secondaryAccountNumber: string;
  ytdRealizedGain: number;
  ytdRealizedLongTermGain: number;
  ytdRealizedShortTermGain: number;
  businessLine: string;
  hasTLH: boolean;
  sleeveCurrentPercent: number;
  sleeveTargetPercent: number;
  sleeveNeedPercent: number;
  billPayMethod: string;
  inBalance: boolean;
  downloadSource: string;
  hasUnassignedHoldings: boolean;
  notesCount: number;
  displayNotes: string;
  numberOfAccounts: number;
  portfolioModelId: number;
  accountModelId: number;
  sleeveContributionPercent: number;
  sleeveDistributionPercent: number;
  setAsideCashCount: number;
  postTradeNeedPercent?: number;
  lastPositionDate: Date;
  modelTags: string;
  sweepCash?: number;
  sweepCashPercent?: number;
  moneyMarketCash?: number;
  moneyMarketCashPercent?: number;
}

/** Defines the customview entity for all Accounts*/
export interface IAccountCustomView {
  id: number;
  name: string;
}

/** Defines the customview entity for all Accounts*/
export interface IAccountFilters {
  id: number;
  name: string;
}

export interface IAccountDashboard {
  dateTime: Date;
  value: IDashboardValueSummary;
  accounts: IDashboardAccountSummary;
  issues: IDashboardIssueSummary;
  bars: IDashboardBarsSummary;
}

/** Defines dashboard issues summary entity */
export interface IDashboardIssueSummary {
  total: number;
  errors: number;
  warnings: number;
}

/** Defines dashboard Value summary entity */
export interface IDashboardValueSummary {
  total: number;
  changeValueAmount: number;
  changeValuePercent: number;
  status: string;
}

/** Defines dashboard Accounts summary entity */
export interface IDashboardAccountSummary {
  total: number;
  existing: number;
  new: number;
  astro: number;
}

/** Defines the bars summary entity */
export interface IDashboardBarsSummary {
  systematic: number;
  accountWithMergeIn: number;
  accountWithMergeOut: number;
  newAccount: number;
  accountWithNoPortfolio: number;
  toDo: number;
  sma: number;
  accountWithDataError: number;
  accountWithPendingTrade: number;
  astroWithTrackingErrorOutOfTolerance: number;
  astroWithRiskOutOfTolerance: number;
  astroWithTaxLossHarvestingOpportunities: number;
  astroWithCashOutOfTolerance: number;
  astroWithNewTemplateAssignments: number;
  optionTradingOpportunities: number;
  optionsExpiringSoon: number;
}

/**Account Details Entity */
export interface IAccountDetails {
  id: number;
  name: string;
  accountNumber: string;
  accountId: string;
  billingAccount: string;
  portfolio: string;
  portfolioId: number;
  custodian: string;
  sleeveType: string;
  advisor: string;
  washSaleGroup: string;
  model: string;
  modelId: number;
  style: string;
  registrationId: number;
  sleeveContributionPercent: number;
  sleeveDistributionPercent: number;
  sleeveTarget: number;
  sleeveToleranceLower: number;
  sleeveToleranceUpper: number;
  smaTradable: string;
  sleeveCurrent: number;
  orionConnectFirmId: number;
  orionConnectExternalId: number;
  isDisabled: number;
  isSleeve: number;
  summarySection: ISummary;
  ytdGl: IYTDGainLossSummary;
  accountValue: IAccountValueSummary;
  errorAndWarnings: IIssuesSummary;
  createdOn: Date;
  createdBy: string;
  editedOn: Date;
  editedBy: string;
  accountType: string;
  isDoNotBuySell: boolean;
  isCustodialRestriction: boolean;
  needAnalyticsStatus: string;
  tradingInstruction: string;
  restrictedPlanId: number;
  restrictedPlanIdRO: number;
  macStatus: number;
  macStatusText: string;
  shortTermTaxRate: number;
  astroEnabled: boolean;
  excludeRebalanceSleeve: string;
  sleeveStrategyId: string;
  sleeveStrategyName: string;
  sleeveStrategyAggregateId: string;
  sleeveStrategyAggregateName: string;
  isMultiAssetClass: boolean;
  subAdvisorName: string;
  entityOptions: any[];
  tags: string;
  postTradeNeedPercent?: number;
  securityDeviationPercent?: number;
  securitySetDeviationPercent?: number;
  subclassDeviationPercent?: number;
  classDeviationPercent?: number;
  doNotTradeText: string;
  categoryDeviationPercent?: number;
  modelTags: string;
  lastTLHDate?: Date;
}

/** */
export interface ISummary {
  grandTotal: number;
  totalValue: number;
  managedValue: number;
  excludedValue: number;
  totalCashValue: number;
  cashReserve: number;
  cashAvailable: number;
  setAsideCash: number;
}

/** */
export interface IYTDGainLossSummary {
  totalGainLoss: number;
  shortTermGL: number;
  longTermGL: number;
  shortTermGLStatus: string;
  longTermGLStatus: string;
  totalGainLossStatus: string;

}

export interface IIssuesSummary {
  systematic: string;
  mergeIn: number;
  mergeOut: number;
  newAccount: boolean;
  hasPortfolios: boolean;
  custodialRestrictions: boolean;
  sma: any; // TODO: Need to change specific type
  importError: number;
  hasPendingTrades: boolean;
}

export interface IAccountValueSummary {
  totalValueOn: Date;
  totalValue: number;
  holdings: IHoldingsSummary[];
  status: string;
}

export interface IHoldingsSummary {
  securityName: string;
  marketValue: number;
  unit: number;
  price: number;
  percentage: number;
  color: string;
  pendingValue: number;
}

/**Define the entity for account simple */
export interface IAccountSimple {
  id: number;
  name: string;
  accountId: string;
  accountType: string;
  accountNumber: string;
  portfolioName: string;
  isDeleted: number;
  isSleeve: number;
  createdOn: Date;
  createdBy: number;
  editedOn: Date;
  editedBy: number;
}


/**Define get Aside cash Details based on AccuontId */
export interface IAsideCash {
  id: number;
  description: string;
  cashAmountTypeId: number;
  cashAmountTypeName: string;
  cashAmount: number;
  expirationTypeId: number;
  expirationTypeName: string;
  expirationValue: any;
  startDate: any;
  percentCalculationType: string;
  toleranceValue: number;
  expiredOn: Date;
  isExpired: boolean;
  isDeleted: boolean;
  createdOn: Date;
  createdBy: string;
  editedOn: Date;
  editedBy: string;
  isActive: boolean;
  // below added for UI use only
  percentCalculationTypeId: number;
  minCashAmount: number;
  minMultiplier?: number;
  maxCashAmount: number;
  maxMultiplier?: number;
  setAsideAccountTypeName: string;
  setAsideAccountTypeId: number;
  modified: boolean;
  tempId: number;
  delete: boolean;
  portfolioSetAsideCashId: number;
  isSystematic: boolean;
  isBilling: boolean;
  isTacticalTradeTool?: boolean;
  expiredBy: number;
  expiredByUser: string;
}

/** Defines Entity For AsideCash Expiration Type */
export interface IAsideCashExpirationType {
  id: number;
  name: string;
}

/** Defines Entity For AsideCash Account Type for Sleeve Portfolio */
export interface IAsideCashAccountType {
  id: number;
  name: string;
}

/** Defines Entity for AsideCash Transaction Type */
export interface IAsideCashTransactionType {
  id: number;
  name: string;
}

/**Define Entity for AsideCash Amount Type */
export interface IAsideCashAmountType {
  id: number;
  name: string;
}

/** Defines entity for sma model levels */
export interface IModelTypes {
  id: number;
  name: string;
}

/** Defines sub nodes entity for model  */
export interface IModelSubNode {
  subModelId: number;
  subModelName: string;
}

/** Defines entity for sma list */
export interface IAccountSMA {
  selectedLevelId: number;
  weightings: ISMAweightings[];
}

/** Defines entity for sma weightings */
export interface ISMAweightings {
  id: number;
  subModelId: number;
  subModelName: string;
  weightPercent: number;
  modelId: number;
  modelDetailId: number;
  isDeleted: number;
  createdOn: Date;
  createdBy: string;
  editedOn: Date;
  editedBy: string;
}

/**Define Entity for Account Audit and Logging */
export interface IAccountSetAsideCashAudit extends IAuditHistoryChanges {
  editedBy: string;
  editedOn: Date;
  changeType: string;
  AccountId: string;
  setAsideCashAmountType: string;
  amount: number;
  description: string;
  expirationType: string;
  expireDate: Date;
  expireTransactionType: string;
  transactionAmount: number;
  transactionAmountTolerance: string;
  systemExpiredOn: Date;
  createdBy: string;
  createdOn: Date;
}

export interface IAccountHistory extends IAuditHistoryChanges {
  portfolioId: string;
  portfolioName: string;
  modelId: string;
  modelName: string;
  astroEnabled: string;
  isDoNotBuySell: string;
  changeType: string;
  createdBy: string;
  createdDate: Date;
  editedBy: string;
  editedDate: Date;
}

/** Define the entity for account simple search */
export interface IAccountSimpleSearch {
  id: number;
  name: string;
  accountId: string;
  accountType: string;
  accountNumber: string;
  astroEnabled: boolean;
  portfolioName: string;
  isDeleted: number;
  isSleeve: boolean;
  modelId: number;
  portfolioId: number;
  value: number;
  createdOn: string;
  createdBy: string;
  editedOn: string;
  editedBy: string;
  macStatus: number;
  sleeveType: string;
  needAnalytics: number;
  failedReason: string;
  hasTaxLossHarvest: boolean;
}

/** Define the entity for account simple search format */
export interface IFormattedAccountSuggestions {
  id: number;
  name: string;
  accountId: string;
  accountType: string;
  accountNumber: string;
  portfolioName: string;
  isDeleted: number;
  isSleeve: boolean;
  modelId: number;
  portfolioId: number;
  value: number;
  createdOn: string;
  createdBy: string;
  editedOn: string;
  editedBy: string;
  displayFormat: string;
  selectedItemFormat: string;
  hasTaxLossHarvest: boolean;
}

/** account template */
export interface IAccountTemplate {
  url: string;
}

/**Define Entity for Account Set Aside Percent Calculation Type */
export interface IAccountSetAsidePercentCalculationType {
  id: number;
  name: string;
}

export interface IDayOfWeekSummary {
  rank: number;
  rankName: string;
  total: number;
  day: number;
  dayOfWeek: number;
  dayName: string;
  monthName: string;
  ordinal: string;
}

export enum SetAsideCreationType {
  Individual = 1,
  DollarCostAverage = 2
}

export enum SetAsideCashDCAPeriodType {
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Annual = 4
}

export enum SetAsideDCAIntervalType {
  Exact = 0,
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Last = 5
}

export interface ISetAsideDCAFrequency {
  period: SetAsideCashDCAPeriodType;
  start: Date;
  every: number;
  on?: {
    interval: SetAsideDCAIntervalType;
    day: number;
  };
}

export interface IAsideCashAmountSchedule {
  amount: number;
  date: Date;
}

export interface IAccountTradeBlockUpdate {
  id: number;
  isDoNotBuySell: boolean;
  isCustodialRestriction: boolean;
}

export enum AccountDoNotTrade {
  IsTradable = 'Is Tradable',
  DoNotTradeAdvisor = 'Do Not Trade(Advisor)',
  DoNotTradeCustodian = 'Do Not Trade(Custodian)',
}
