import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, noop, Subscription } from 'rxjs';
import { Utils as Util } from '../../core/functions';
import { TagType } from './tagtype';
import { TagsService } from '../../services/tags.service';
import { ITag } from '../../models/tags';
import { finalize, tap } from 'rxjs/operators';
import { MultiSelect } from 'primeng/multiselect';
import { OverlayOptions } from 'primeng/api';
import * as Consts from '../../libs/app.constants';
import { replace as _replace } from 'lodash';

@Component({
  selector: 'eclipse-tags',
  templateUrl: './tags.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TagsComponent,
    },
  ],
})
export class TagsComponent implements ControlValueAccessor {
  static readonly TOO_MANY_CHARACTERS: string = 'Too many characters in selected tags.  Only 500 characters are allowed.'
  static readonly DROPDOWN_VIRTUALIZATION_THRESHOLD: number = 500; // Max number of items to allow in the list before turning on dropdown virtualization.

  @ViewChild('newTagInput') newTagInput: ElementRef;
  @ViewChild('multiSelect') multiSelect: MultiSelect;
  public overlayOptions: OverlayOptions = {
    style: {'max-width': 'calc(89vw)'},
  };
  public virtualScroll: boolean = false;
  public isLoading: boolean = false;
  public readonly canDelete: boolean;
  public readonly canAdd: boolean;
  public newTagName: string; // Name of the new tag being created by the user
  public selectedTags: ITag[] = []; // The selected tags
  public availableTags: ITag[] = []; // List of tags for the tag type, plus any unmatched tags from the entity
  public onChange: (value: string) => void = noop;
  public onTouch: () => void = noop;
  public validationError: string;
  public isSavingNewTag: boolean = false;
  public isDisabled: boolean = false;
  public isAddingNewTag: boolean = false;
  private backupTags: ITag[] = [];
  private tagSubscription: Subscription;
  private tag$: BehaviorSubject<ITag[]> = new BehaviorSubject([]);

  private readonly _tagsService = inject(TagsService);

  constructor() {
    const tagsPermission = Util.getPermission(Consts.PRIV_TAGS);
    this.canAdd = tagsPermission?.canAdd ?? false;
    this.canDelete = tagsPermission?.canDelete ?? false;
  }

  public get tagDisplay(): string {
    return this.selectedTags?.map(tag => tag.value)
      .join('\n');
  }

  private _tagType: TagType;

  public get tagType(): TagType {
    return this._tagType;
  }

  @Input()
  public set tagType(value: TagType) {
    this._tagType = value;
    this.getTags();
  }

  /**
   * Begin adding a new tag.
   */
  public startAddNew() {
    this.newTagName = null;
    this.isAddingNewTag = true;
    setTimeout(() => {
      this.newTagInput.nativeElement.focus();
    }, 0);
  }

  /**
   * Stop adding a new tag.
   */
  public cancelAddNew() {
    this.isAddingNewTag = false;
  }

  /**
   * User has changed the selected tags.
   */
  public onSelectedTagsChanged() {
    this.validationError = null;

    // Validate the selected tags for length.  If too long,
    // revert back to the previous selection.
    if (!this.isSelectedTagLengthValid(this.selectedTags)) {
      this.validationError = TagsComponent.TOO_MANY_CHARACTERS;
      this.selectedTags = [...this.backupTags];
      return;
    }

    let tagsCombined = this.selectedTags?.map(tag => tag.value).join(',');
    if (!tagsCombined?.length) {
      tagsCombined = null;
    }
    this.backupTags = [...this.selectedTags ?? []];

    this.onChange(tagsCombined);

    // Realign the overlay in case the height of the multiselect changed as a result of the selection.
    // Otherwise, the overlay will stay anchored to the same position relative to the multiselect.
    setTimeout(() => {
      this.multiSelect.overlayViewChild?.alignOverlay();
    });
  }

  /**
   * Validate the length of a list of tags.
   * @param tags
   * @private
   */
  private isSelectedTagLengthValid(tags: ITag[]): boolean {
    const tagsCombined = tags?.map(tag => tag.value).join(',') ?? '';
    return tagsCombined.length <= 500;
  }

  /**
   * Keydown event on the new tag name field.
   * Prevents commas because those are used as the tag separators.
   * @param event
   */
  public onNewTagNameKeyDown(event){
    if(event.code === 'Comma') {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Creates a new tag and sets it as selected.
   */
  public createNewTag() {
    this.newTagName = _replace(this.newTagName, ',', '').trim();

    if (!this.newTagName?.length) {
      return;
    }

    const newTag = <ITag>{value: this.newTagName, tagTypeId: +this.tagType};
    this.isSavingNewTag = true;

    this._tagsService.createTags([newTag])
      .pipe(finalize(() => {
        this.isSavingNewTag = false;
        this.isAddingNewTag = false;
      }))
      .subscribe(results => {
        this.availableTags = [...this.availableTags, ...results];
        this.sortAvailableTags();
        // Validate that adding the new tags to the existing selected tags won't
        // be longer than the allowed number of characters.  If valid, select the new tags.
        if (this.isSelectedTagLengthValid([...this.selectedTags, ...results])) {
          this.selectedTags.push(...results);
          this.onSelectedTagsChanged();
        }
        setTimeout(() => {
          // PrimeNG multiselect hack - activate the filter and tell it to detect changes,
          // or else any filters on the list won't refresh and the new tag won't show up.
          this.multiSelect.activateFilter?.();
          this.multiSelect.cd?.detectChanges();
          this.multiSelect.overlayViewChild?.alignOverlay();
        });
      });
  }

  /**
   * Deletes a tag.  Removes the tag from the available and selected lists.
   * @param evt
   * @param tag
   */
  public deleteTag(evt, tag) {
    evt?.preventDefault();
    evt?.stopPropagation();
    tag.isDeleting = true;
    this._tagsService.deleteTag(tag)
      .pipe(
        finalize(() => {
          tag.isDeleting = false;
        }),
      )
      .subscribe(() => {
        this.selectedTags = this.selectedTags.filter(t => t.value !== tag.value);
        this.backupTags = [...this.selectedTags];
        this.availableTags.splice(this.availableTags.indexOf(tag), 1);
        setTimeout(() => {
          // PrimeNG multiselect hack - activate the filter and tell it to detect changes,
          // or else any filters on the list won't refresh and the old tag won't go away.
          this.multiSelect.activateFilter?.();
          this.multiSelect.cd?.detectChanges();
          this.multiSelect.overlayViewChild?.alignOverlay();
        });
      });
  }

  /**
   * Gets the list of tags by tag type
   */
  public getTags() {
    if (!this._tagType) {
      this.availableTags = [];
      return;
    }
    this.isLoading = true;
    this._tagsService.getTagsByByTagType(this._tagType)
      .subscribe(results => {
        this.tag$.next(results);
        this.isLoading = false;
      });
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(value: string): void {
    const tags = value?.split(',')
      .filter(t => t?.length)
      .map(t => {
        return {
          tagTypeId: this.tagType,
          value: t,
          isEditing: false,
          isDeleting: false,
          isUnapprovedTag: false,
        };
      });
    this.addUserTagsToAvailableTags(tags);
  }

  /**
   * Creates a list of availalbe tags.
   * The tags list is a combination of available tags and the tags on the entity.  The list is
   * concatenated together because a custom tag may have been added to the entity that was not
   * previously saved.
   *
   * @param tags Tags from the entity
   * @private
   */
  private addUserTagsToAvailableTags(tags: ITag[]) {
    this.tagSubscription?.unsubscribe();
    this.tagSubscription = this.tag$.pipe(
      tap((baseTags) => {
        this.availableTags = [...baseTags];
        tags?.forEach(tag => {
          if (this.availableTags.findIndex((existingTags: ITag) => existingTags.value === tag.value) === -1) {
            tag.isUnapprovedTag = true;
            this.availableTags.push(tag);
            console.warn('Unsaved tag found', tag);
          }
        });
        this.sortAvailableTags();
        // Set virtual scrolling on when more than 500 tags exist to prevent unnecessary rendering
        this.virtualScroll = this.availableTags?.length > TagsComponent.DROPDOWN_VIRTUALIZATION_THRESHOLD;
        this.selectedTags = tags?.map(t => {
          return this.availableTags.find(at => at.value === t.value);
        }) ?? [];
        this.backupTags = [...this.selectedTags];
      }),
    ).subscribe();
  }

  /**
   * Sorts tags alphabetically.
   * @private
   */
  private sortAvailableTags() {
    this.availableTags = Util.sortBy(this.availableTags, 'value');
  }
}
