<p-multiSelect *ngIf="!isLoading; else loading" #multiSelect [pTooltip]="tagDisplay" [autoHide]="false"
               display="chip" optionLabel="value" [showToggleAll]="false" [showClear]="false"
               styleClass="p-multiselect-wrap p-multiselect-compressed"
               filterPlaceHolder="Search for a tag" placeholder="Select Tags" appendTo="body" [filter]="true"
               filterBy="value" emptyFilterMessage="No tags found" [virtualScroll]="virtualScroll" [virtualScrollItemSize]="27"
               [options]="availableTags" [(ngModel)]="selectedTags"
               (onPanelHide)="cancelAddNew()"
               (onChange)="onSelectedTagsChanged()"
               [overlayOptions]="overlayOptions"
               [disabled]="isDisabled">
  <ng-template let-tag pTemplate="item">
    <div *ngIf="!tag.isEditing" class="flex"
         style="justify-content:space-between; align-items: center; flex: 1; gap: .5rem;">
      <span style="word-break: break-all; flex-shrink: 1; max-width: 50vw;">{{ tag.value }}</span>
      <button *ngIf="canDelete" pButton class="p-button-secondary p-button-sm" (click)="deleteTag($event, tag)"
              icon="far fa-fw {{tag.isDeleting ? 'fa-spin fa-spinner' : 'fa-trash'}}"></button>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="p-x-10 p-y-10 flex" style="justify-content: space-between; align-items: center; border-top: 1px solid var(--select-stroke-primary);" *ngIf="canAdd">
      <button [disabled]="isSavingNewTag" pButton *ngIf="!isAddingNewTag" class="p-button-text" label="Create New Tag"
              icon="far fa-circle-plus" (click)="startAddNew()"></button>
      <span *ngIf="validationError?.length" class="validationerror">{{validationError}}</span>
      <div *ngIf="isAddingNewTag" class="flex" style="flex: 1;">
         <span class="p-input-icon-left m-r-10" style="flex: 1;">
          <i class="far fa-fw fa-pencil"></i>
          <input [disabled]="isSavingNewTag" #newTagInput pInputText placeholder="Create a new tag" (keyup.enter)="createNewTag()" (keydown)="onNewTagNameKeyDown($event)"
                 [(ngModel)]="newTagName" required autofocus maxlength="250" minlength="1"/>
         </span>
        <button [disabled]="isSavingNewTag" pButton (click)="cancelAddNew()" class="p-button-secondary"
                icon="far fa-fw fa-times"></button>
        <button [disabled]="isSavingNewTag || !newTagName?.length" pButton (click)="createNewTag()"
                icon="fas fa-fw {{isSavingNewTag ? 'fa-spin fa-spinner' : 'fa-check'}}"></button>
      </div>
    </div>
  </ng-template>
</p-multiSelect>
<ng-template #loading>
  <p-skeleton height="2.25em"></p-skeleton>
</ng-template>
