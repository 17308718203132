import { Component, ViewChild, Input, Output, EventEmitter, Inject } from '@angular/core';
import { SecurityService } from '../../services/security.service';
import { BaseComponent } from '../../core/base.component';
import { GridOptions, ColDef, GridApi } from '@ag-grid-community/core';
import * as Consts from '../../libs/app.constants';
import { BlendedFundService } from '../../services/blendedFund.service';
import { MACWeightingValidationComponent } from './macweighting.validation.component';
import { IMACSecurityWeightings, IWeightingNav, IWeightingData } from '../../models/blendedfund';
import { WINDOW } from '../../providers/window.provider';
import { Utils } from '../../core/functions';
import { SplitIoService } from '../../core/feature-flag/splitio.service';
import { IMACSecurityAsset, IMACSecurityWeighting, IMACWeighting } from '../../models/modeling/model';
import { ModelSubModelTypes } from '../../libs/submodel.enums';

/**
 *Using this component from Model View/Edit, Portfolio Edit,  Account View & Edit and Assign MAC
 */
@Component({
  selector: 'eclipse-security-weightings',
  templateUrl: './security.weighting.component.html',
  providers: [SecurityService, BlendedFundService]
})

export class SecurityWeightingComponent extends BaseComponent {
  public gridApi: GridApi;
  public securitiesGridOptions: GridOptions;
  public securitiesColDefs: ColDef[] = [];
  public securitiesGridMasterData: IMACSecurityWeightings[] = []; // master data of grid
  public securitiesGridData: IMACSecurityWeightings[] = []; // visble records of grid
  protected securityWeightings: IWeightingData[] = [];
  public taxableAlternateOptions: any[] = [
    { id: 1, text: 'Yes' },
    { id: 0, text: 'No' }
  ];

  disableSearch: boolean = false;
  public validationLevel: number = 1;
  securitySuggestions: any;
  private selectedIds: string;
  selectedSecurity: any;
  disableNextBtn: boolean = true;
  disableAddButton: boolean = true;
  isViewOnly: boolean = false;

  rankValidationMessage: string;
  macStatus: number;
  displayMACLabel: boolean;
  hideSearchAndGrid: boolean = false;
  macWeightingStatusList: any[] = [];
  taxableErrorMsg: string = '';
  initialMacStatus: number;
  isEditAction: boolean; // flag to check whether to save data after validation or not
  showErrorDialog: boolean;
  macSecurityLevel: string;
  macWarningModelLevels: string;
  isMacModelValidationFFEnabled: boolean;
  selectedMACSecurityId: number;
  securityMacWeightings: Map<number, IMACSecurityWeighting[]>;
  targetModelLevel: number;

  @Output() parentCallback = new EventEmitter();
  @Output() navigateCallback = new EventEmitter();
  @Input() weightingModel: IWeightingNav;
  @Input() isModelAssign: boolean; // If it is from MAC Assignment on Model
  @Input() modelMacClassifications: IMACSecurityAsset[];
  @Input() isModel: boolean;
  @Input() modelLevels: Set<string>;

  @ViewChild(MACWeightingValidationComponent) macWeightingValidationComponent: MACWeightingValidationComponent;

  constructor(private readonly _blendedFundService: BlendedFundService, @Inject(WINDOW) private readonly window: Window, private readonly _splitIoService: SplitIoService) {
    super();
    this.securitiesGridOptions = {
      ...this.defaultGridOptions,
      sideBar: null,
    };

    this.createSecuritiesColDefs();
    // MAC Status Options preperation
    this.macStatus = Consts.MACWeightingStatus.Yes.value;
    this.initialMacStatus = Consts.MACWeightingStatus.Yes.value;
    this.displayMACLabel = true;
    this.macWeightingStatusList = [];
    this.macWeightingStatusList.push({ id: Consts.MACWeightingStatus.Model.value, name: Consts.MACWeightingStatus.Model.text });
    this.macWeightingStatusList.push({ id: Consts.MACWeightingStatus.Yes.value, name: Consts.MACWeightingStatus.Yes.text });
    this.macWeightingStatusList.push({ id: Consts.MACWeightingStatus.No.value, name: Consts.MACWeightingStatus.No.text });
    // End

    this.showErrorDialog = false;
    this.macSecurityLevel = null;
    this.macWarningModelLevels = null;
    this.securityMacWeightings = new Map();
    this.targetModelLevel = null;
    this.getMacModelValidationFeatureFlag();
  }
  /** Fires on component initialisation */
  ngOnInit() {
    // eslint-disable-next-line eqeqeq
    if (this.weightingModel != undefined) {

      this.getWeightingStatusList();

      if (this.weightingModel.macStatus) {
        this.macStatus = this.weightingModel.macStatus;
        this.initialMacStatus = this.weightingModel.macStatus; // For Edit action
      }

      if (this.weightingModel.isEdit) {
        this.displayMACLabel = false;
        this.isEditAction = true;
        this.onMacStatusChange(this.macStatus); // to hide/show grid n search, update text on panel
      }

      // For 'CREATE NEW MODEL' initially hide the grid as status will be 'NO'.
      // eslint-disable-next-line eqeqeq
      if (this.weightingModel.id == undefined && this.weightingModel.isEdit == false) {
        // eslint-disable-next-line eqeqeq
        if (this.weightingModel.macStatus != Consts.MACWeightingStatus.Yes.value) {
          this.hideSearchAndGrid = true;
        }
      }
      // View only Grid changes for View Account Details & Model Details
      // eslint-disable-next-line eqeqeq
      if (this.weightingModel.id != undefined && this.weightingModel.isEdit == false) {
        this.isViewOnly = true;
        this.createSecuritiesColDefs(); // to hide delete column recreate definations
      }
      this.securitiesGridMasterData = [];
      this.securitiesGridData = [];
      this.bindWeightings();

    }

    if (this.isModelAssign) {// If it is from Model -> Assign MAC Fund.
      this.macWeightingStatusList = this.macWeightingStatusList.filter(p => p.name !== Consts.MACWeightingStatus.Model.text);
    }
  }

  /** Fires on item select */
  onSecuritySelect(item) {
    this.selectedSecurity = item;
    this.disableAddButton = false;
  }
  /**Security search */
  getSecurities(event) {
      this._blendedFundService.searchMACSecurities(event.query)
      .subscribe(model => {
        const addedSecurityIds = [];
        if (model && model.length > 0) {
          this.securitiesGridMasterData.forEach(s => {
            // eslint-disable-next-line eqeqeq
            if (s.securityId && s.action != Consts.MACEditActions.Delete) {
              addedSecurityIds.push(s.securityId);
            }
          });
          if (addedSecurityIds.length > 0) {
            this.securitySuggestions = model.filter(x => {
              if (addedSecurityIds.indexOf(x.securityId) <= -1) {
                return x;
              }
            });
          } else {
            this.securitySuggestions = model;
          }
        } else {
          this.securitySuggestions = [];
        }
      });
  }

  addSecurity(): void {
    this.disableAddButton = true;
    let addDeletedSecurity = false;
    // eslint-disable-next-line eqeqeq
    if (this.selectedSecurity != undefined) {
      // Checking of duplicate entry
      if (this.securitiesGridMasterData.length > 0) {
        // eslint-disable-next-line eqeqeq
        const match = this.securitiesGridMasterData.filter(m => m.securityId == this.selectedSecurity.securityId);
        if (match.length > 0) {
          // If security is deleted and adding back, need to change 'ACTION'
          // eslint-disable-next-line eqeqeq
          const isDeleted = match.find(record => record.action == Consts.MACEditActions.Delete);
          if (isDeleted) {
            addDeletedSecurity = true;
            // eslint-disable-next-line eqeqeq
            this.securitiesGridMasterData = this.securitiesGridMasterData.filter(m => m.securityId != this.selectedSecurity.securityId); // Excluding security to get fresh data again
          } else {
            return; // as security already added to grid, just return
          }
        }
      }
      // Get Weightings & rank of the security
        this._blendedFundService.getBlendedFunds(this.selectedSecurity.securityId)
        .subscribe((model: any) => {
          const self = this;
          let weightingId = 0;
          // prepare weighting list
          if (model && model.length > 0) {
            model.forEach(function (item) {
              self.securityWeightings.push({ id: item.id, weightingName: item.weightingName });
            });
            // eslint-disable-next-line eqeqeq
            const selected = model.filter(item => item.isDefault == 1);
            weightingId = selected && selected.length > 0 ? selected[0].id : model[0].id;
          }
          // Add security to the Grid
          this.securitiesGridMasterData.push({
            securityId: this.selectedSecurity.securityId, securitySymbol: this.selectedSecurity.securitySymbol,
            macWeightingList: this.securityWeightings,
            weightingId: +weightingId, taxableSecurity: model.taxableSecurity, taxDeferredSecurity: model.taxDeferredSecurity,
            taxExemptSecurity: model.taxExemptSecurity, rank: model.rank ? model.rank : null,
            action: addDeletedSecurity ? Consts.MACEditActions.Update : Consts.MACEditActions.Add, // condition to pass action as UPDATE if adding it back
            editId: null
          });
          // eslint-disable-next-line eqeqeq
          this.securitiesGridData = this.securitiesGridMasterData.filter(item => item.action != Consts.MACEditActions.Delete); // For Edit MAC
          this.gridApi?.setGridOption('rowData', this.securitiesGridData);
          this.securityWeightings = [];

          // Enable/Disable Save Button of Parent component
          this.parentCallback.emit({ action: 'disableSave', value: (this.securitiesGridData.length > 0) ? false : true });

          if (this.isMacModelValidationFFEnabled && !!this.selectedSecurity && this.isModel) {
            this.securityMacWeightings.set(this.selectedSecurity.securityId, model);
            const security = model.find(securityWeighting => securityWeighting.isDefault);
            if (security) {
              this.verifyMacWeightingsInModel(security.weightingList);
            } else {
              this.verifyMacWeightingsInModel(model[0].weightingList);
            }
          }
          this.selectedSecurity = undefined;
        });
    }
  }

  verifyMacWeightingsInModel(securityWeightings: IMACWeighting[]): void {
    this.setModelLevels();
    let hasMatchingMacClassification: boolean = false;
    for (const macClassification of this.modelMacClassifications) {
      if (this.targetModelLevel === macClassification.modelTypeId) {
        hasMatchingMacClassification = securityWeightings.some(weighting => macClassification.modelTypeId === weighting.assetClassificationId &&
          macClassification.modelSecurityAssetName === weighting.assetClassificationType);
        if (hasMatchingMacClassification) {
          break;
        }
      }
    }

    if (!this.modelMacClassifications.length || !hasMatchingMacClassification) {
      this.setMacSecurityLevels(securityWeightings);
    }
  }

  setModelLevels(): void {
    if (this.modelLevels.size) {
      const modelLevelIds = [];
      const modelLevels = [];
      for (let level of this.modelLevels) {
        level = level.replace(' ', '');
        modelLevels.push(level);
        modelLevelIds.push(ModelSubModelTypes[level]);
      }
      this.macWarningModelLevels = modelLevels.join(', ');
      this.targetModelLevel = Math.max(...modelLevelIds);
    }
  }

  setMacSecurityLevels(securityWeightings: IMACWeighting[]): void {
    const macSecurityLevels = new Set<string>();
    for (const weighting of securityWeightings) {
      if (weighting.assetClassificationName) {
        macSecurityLevels.add(weighting.assetClassificationName);
      }
    }
    this.macSecurityLevel = [...macSecurityLevels].join(', ');
    this.showErrorDialog = true;
  }

  verifyMacWeightings(weightingIndex: number): void {
    const macSecurityWeighting = this.securityMacWeightings.get(this.selectedMACSecurityId);
    if (macSecurityWeighting) {
      this.verifyMacWeightingsInModel(macSecurityWeighting[weightingIndex].weightingList);
    } else {
      this._blendedFundService.getBlendedFunds(this.selectedMACSecurityId).subscribe((model: IMACSecurityWeighting[]) => {
        this.securityMacWeightings.set(this.selectedMACSecurityId, model);
        this.verifyMacWeightingsInModel(model[weightingIndex].weightingList);
      });
    }
  }

  /** To render Weighting Dropdown cell */
  weightingCellRenderer(params, self) {
    if (!self.isViewOnly) {
      // self.securityId = params.data['securityId'];
      const eCell = document.createElement('div');
      eCell.style.cssText = 'height:20px;';
      const eSelect = document.createElement('select');
      eSelect.style.cssText = 'width:80%;';

      // let list = self.securitiesGridData.filter(x => x.securityId == self.securityId);
      const weightings = params.data.macWeightingList ? params.data.macWeightingList : [];
      // eslint-disable-next-line eqeqeq
      if (weightings != undefined && weightings.length > 0) {
        weightings.forEach(function (item) {
          const eOption = document.createElement('option');
          eOption.setAttribute('value', item.id);
          eOption.innerHTML = item.weightingName;
          eSelect.appendChild(eOption);
        });
      }
      // eslint-disable-next-line eqeqeq
      eSelect.value = params.data['weightingId'] == undefined ? '0' : params.data['weightingId'];
      eCell.appendChild(eSelect);
      eSelect.focus();

      eSelect.addEventListener('change', function () {
        // eslint-disable-next-line eqeqeq
        const newValue = eSelect.value == undefined ? '' : eSelect.value;
        params.data[params.colDef.field] = newValue;
        params.data['weightingId'] = eSelect.value;
        if (params.data['action'] !== Consts.MACEditActions.Add) {
          params.data['action'] = Consts.MACEditActions.Update;
        }
        if (self.isMacModelValidationFFEnabled) {
          self.verifyMacWeightings(eSelect.selectedIndex);
        }
        const updatedNodes = [];
        updatedNodes.push(params.node);
        self.gridApi.refreshCells({rowNodes: updatedNodes});
      });

      return eCell;
    } else {
      const weightings = params.data.macWeightingList;
      if (weightings && weightings.length > 0) {
        // eslint-disable-next-line eqeqeq
        const selectedWeighting = weightings.find(w => w.id == params.data.weightingId);
        const weightingName = selectedWeighting ? selectedWeighting.weightingName : ' ';
        // eslint-disable-next-line prefer-template
        return '<span title="' + weightingName + '">' + weightingName + '</span>';
      } else {
        return '<span></span>';
      }
    }

  }

  // getSelectedView(viewName) {
  //     this.navigateCallback.emit({ action: "back" })
  // }

  /** To Create ColDefs */
  createSecuritiesColDefs() {
    const self = this;
    this.securitiesColDefs = [];
    this.securitiesColDefs.push(<ColDef>{ headerName: '', field: 'securityId', hide: true });
    this.securitiesColDefs.push(<ColDef>{ headerName: 'Ticker ', field: 'securitySymbol', cellClass: 'text-center', width: 150 });
    this.securitiesColDefs.push(<ColDef>{
      headerName: 'Weighting', field: 'weightingId', width: 230, cellClass: 'text-center', cellRenderer: function (params) {
        return self.weightingCellRenderer(params, self);
      }
    });
    this.securitiesColDefs.push(<ColDef>{
      headerName: 'T', width: 100, headerTooltip: 'Taxable', cellClass: 'text-center', sortable: false, field: 'taxableSecurity', filter: 'agTextColumnFilter',
      cellRenderer: function (params) {
        return self.alternativeCellRenderer(params, self);
      }
    });
    this.securitiesColDefs.push(<ColDef>{
      headerName: 'TD', width: 100, headerTooltip: 'Tax Deferred', cellClass: 'text-center', sortable: false, field: 'taxDeferredSecurity', filter: 'agTextColumnFilter',
      cellRenderer: function (params) {
        return self.alternativeCellRenderer(params, self);
      }
    });
    this.securitiesColDefs.push(<ColDef>{
      headerName: 'TE', width: 100, headerTooltip: 'Tax Exempt', cellClass: 'text-center', sortable: false, field: 'taxExemptSecurity', filter: 'agTextColumnFilter',
      cellRenderer: function (params) {
        return self.alternativeCellRenderer(params, self);
      }
    });
    this.securitiesColDefs.push(<ColDef>{
      headerName: 'Rank', field: 'rank', width: 150,
      cellRenderer: function (params) {
        return self.rankEditorRenderer(params, self);
      }, cellClass: 'text-center'
    });
    if (!self.isViewOnly) {
      this.securitiesColDefs.push(<ColDef>{
        headerName: '', field: '', width: 70, hide: self.isViewOnly,
        cellRenderer: function (params) {
          return self.deleteWeightingRender(params, self);
        }
      });
    }
  }

  private alternativeCellRenderer(params, self) {
    if (!this.isViewOnly) {
      const eCell = document.createElement('div');
      eCell.style.cssText = 'height:20px;';
      const eSelect = document.createElement('select');
      eSelect.style.cssText = 'width:80%;';

      const list = this.taxableAlternateOptions;
      // eslint-disable-next-line eqeqeq
      if (list != undefined && list.length > 0) {
        list.forEach(function (item) {
          const eOption = document.createElement('option');
          eOption.setAttribute('value', item.id);
          eOption.innerHTML = item.text;
          eSelect.appendChild(eOption);
        });
      }

      // eslint-disable-next-line eqeqeq
      if (params.colDef.headerName == 'T') {
        // eslint-disable-next-line eqeqeq
        eSelect.value = (params.data.taxableSecurity != undefined) ? params.data.taxableSecurity : '1'; // when value is ZERO
      // eslint-disable-next-line eqeqeq
      } else if (params.colDef.headerName == 'TD') {
        // eslint-disable-next-line eqeqeq
        eSelect.value = params.data.taxDeferredSecurity != undefined ? params.data.taxDeferredSecurity : '1';
      // eslint-disable-next-line eqeqeq
      } else if (params.colDef.headerName == 'TE') {
        // eslint-disable-next-line eqeqeq
        eSelect.value = params.data.taxExemptSecurity != undefined ? params.data.taxExemptSecurity : '1';
      }
      params.data[params.colDef.field] = eSelect.value;
      eCell.appendChild(eSelect);
      eSelect.focus();

      eSelect.addEventListener('change', function () {
        // eslint-disable-next-line eqeqeq
        const newValue = eSelect.value == undefined ? '' : eSelect.value;
        params.data[params.colDef.field] = newValue;

        // eslint-disable-next-line eqeqeq
        if (params.data['action'] && params.data['action'] == Consts.MACEditActions.Add) {
          params.data['action'] = Consts.MACEditActions.Add;
        } else {
          params.data['action'] = Consts.MACEditActions.Update;
        }


        const updatedNodes = [];
        updatedNodes.push(params.node);
        self.gridApi.refreshCells({rowNodes: updatedNodes});
      });

      return eCell;
    } else {
      let selectedValue = 0;
      // eslint-disable-next-line eqeqeq
      if (params.colDef.headerName == 'T') {
        // eslint-disable-next-line eqeqeq
        selectedValue = params.data.taxableSecurity != undefined ? (+params.data.taxableSecurity) : 1;
      // eslint-disable-next-line eqeqeq
      } else if (params.colDef.headerName == 'TD') {
        // eslint-disable-next-line eqeqeq
        selectedValue = params.data.taxDeferredSecurity != undefined ? (+params.data.taxDeferredSecurity) : 1;
      // eslint-disable-next-line eqeqeq
      } else if (params.colDef.headerName == 'TE') {
        // eslint-disable-next-line eqeqeq
        selectedValue = params.data.taxExemptSecurity != undefined ? (+params.data.taxExemptSecurity) : 1;
      }

      // eslint-disable-next-line eqeqeq
      if (selectedValue == 0) {
        return '<span>No</span>';
      } else {
        return '<span>Yes</span>';
      }
    }

  }
  private rankEditorRenderer(params, self) {
    if (!this.isViewOnly) {
      const eInput = document.createElement('input');
      eInput.className = 'form-control grid-input';

      // eslint-disable-next-line eqeqeq
      eInput.value = params.data[params.colDef.field] == undefined ? '' : params.data[params.colDef.field];

      eInput.addEventListener('blur', function (event) {
        // eslint-disable-next-line eqeqeq
        if (params.data['action'] && params.data['action'] == Consts.MACEditActions.Add) {
          params.data['action'] = Consts.MACEditActions.Add;
        } else {
          params.data['action'] = Consts.MACEditActions.Update;
        }

        // if (eInput.value == "0") {
        //     eInput.value = null;
        // }
        if (!isNaN(parseFloat(eInput.value))) {
          if (self.securitiesGridData && self.securitiesGridData.length > 1) {
            // eslint-disable-next-line eqeqeq
            const securityRanks = self.securitiesGridData.filter(s => s.rank == eInput.value);
            if (securityRanks && securityRanks.length < 1) {
              params.data[params.colDef.field] = parseFloat(eInput.value);
              self.checkAllRanksEntered(self); // condition to check whether all uniq ranks entered
            } else {
              // eslint-disable-next-line eqeqeq
              if (params.data[params.colDef.field] == eInput.value) {
                // clicked in particular textbox and then clicked outside - handling No Value change here
              } else {
                eInput.value = null;
                params.data[params.colDef.field] = null;
              }
            }
          } else {
            params.data[params.colDef.field] = eInput.value;
            self.checkAllRanksEntered(self);
          }
        } else {
          eInput.value = null;
          params.data[params.colDef.field] = null;
        }
      });

      eInput.addEventListener('keypress', function (event) {
        return Utils.isValidIntegerInputKey(event);
      });

      return eInput;
    } else {
      // eslint-disable-next-line eqeqeq
      const val = params.data[params.colDef.field] == undefined ? '' : params.data[params.colDef.field];
      // eslint-disable-next-line prefer-template
      return '<span>' + val + '</span>';
    }

  }
  checkAllRanksEntered(self) {
    // If it is 4th validation level and unique ranks are entered for securities then enable save button
    if (this.validationLevel === 4) {
      const ranks = self.securitiesGridData.filter(s => s.rank || s.rank === 0);
      if (ranks && ranks.length > 0 && ranks.length === self.securitiesGridData.length) {
        self.parentCallback.emit({ action: 'disableSave', value: 0 });
        this.rankValidationMessage = null;
      } else {
        self.parentCallback.emit({ action: 'disableSave', value: 1 });
      }
    }
  }
  deleteWeightingRender(params, self) {
    if (!self.isViewOnly) {
      const eInput = document.createElement('span');
      // eslint-disable-next-line prefer-template
      const result = '<i id=' + params.node.data.securityId + ' title="DeleteMAC" class="fas fa-times red" aria-hidden="true"></i>';
      eInput.innerHTML = result;
      eInput.addEventListener('click', function (event) {
        self.deleteWeighting(params);
      });
      return eInput;
    }
  }

  /** Refresh the ag-grid after delete performed */
  private deleteWeighting(row) {
    row.data['action'] = Consts.MACEditActions.Delete;
    // eslint-disable-next-line eqeqeq
    const security = this.securitiesGridMasterData.find(record => record.securityId == row.data['securityId']);
    if (security) {
      security.action = Consts.MACEditActions.Delete;
    }

    // Exclude 'Delete' action records
    // eslint-disable-next-line eqeqeq
    this.securitiesGridData = this.securitiesGridMasterData.filter(item => item.action != Consts.MACEditActions.Delete);
    this.gridApi.setGridOption('rowData', this.securitiesGridData);

    // Enable/disable Next button on page based on selected port/model count
    this.parentCallback.emit({ action: 'disableSave', value: (this.securitiesGridData.length > 0) ? false : true });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    // AutoSize all columns
    this.gridApi.autoSizeColumns([]);
  }

  ngOnDestroy() {
    // this._sessionHelper.removeItem("selectedIds");
  }

  editCellRender(params, self) {
    const eInput = document.createElement('span');
    // eslint-disable-next-line prefer-template
    eInput.innerHTML = '<i class="fas fa-edit cursor" aria-hidden="true" id =' + params.node.data.securitySetId + ' value=' + params.node.data.securitySetId + ' title="Edit"></i>';
    eInput.addEventListener('click', (event) => {
      self.window.open(`#/eclipse/strategy/securityset/edit/${params.node.data.securitySetId}`);
    });
    return eInput;
  }

  onActionFromValidation(event) {
    if (event && event.action) {
      this.validationLevel = this.macWeightingValidationComponent.validationLevel;
      if (event.action === 'rankValidation') {
        this.rankValidationMessage = event.errorMessage;
      } else if (event.action === 'save') {
        if (event.status === 'success') {
          this.rankValidationMessage = undefined;
          this.validationLevel = 1;
          //this.ngOnInit();
          this.parentCallback.emit({ action: 'save', status: 'success' });
        } else {
          // this.validationLevel = 1;// validate again if clicks on 'SAVE' (after failed)
          this.parentCallback.emit({ action: 'save', status: 'failed' });
        }
      } else if (event.action === 'taxableError') {
        this.taxableErrorMsg = 'At least one of the T/TD/TE should be set to ‘Yes’ for each row.';
        this.parentCallback.emit({ action: 'disableSave', value: 0 });
      } else if (event.action === 'level') {
        this.validationLevel = event.validationLevel;
      } else if (event.action === 'cancel') {
        this.parentCallback.emit({ action: 'disableSave', value: 0 });
      }
    }
  }

  bindWeightings() {
    if (this.weightingModel.id
      && this.weightingModel.type
      && ((this.weightingModel.macStatus === Consts.MACWeightingStatus.Yes.value && this.weightingModel.type === Consts.MacEntityTypes.Model)
        || this.weightingModel.macStatus === Consts.MACWeightingStatus.Sleeve.value
        || this.weightingModel.macStatus === Consts.MACWeightingStatus.Portfolio.value)) {
        this._blendedFundService.getAssignedMACsecuritiesAndWeightings(this.weightingModel.type, this.weightingModel.id)
        .subscribe( (model: IMACSecurityWeightings[] ) => {
          this.securitiesGridMasterData = model;
          // eslint-disable-next-line eqeqeq
          this.securitiesGridData = this.securitiesGridMasterData.filter(item => item.action != Consts.MACEditActions.Delete); // For Edit
          this.securitiesGridData.forEach(w => {
            w.editId = w.weightingId;
          });
          this.securitiesGridMasterData.forEach(w => {
            w.editId = w.weightingId;
          });
          this.gridApi?.setGridOption('rowData', this.securitiesGridData);

          // update status text
          this.parentCallback.emit({ action: 'statusChange', value: this.macStatus });
        });
      }
    }

  onMacStatusChangeUI() {
    this.onMacStatusChange(this.macStatus);
  }

  onMacStatusChange(macStatus) {
    this.taxableErrorMsg = undefined;
    this.macStatus = +macStatus;
    if (!(this.macStatus === Consts.MACWeightingStatus.Yes.value && (!this.weightingModel || this.weightingModel.type === Consts.MacEntityTypes.Model))
        && this.macStatus !== Consts.MACWeightingStatus.Sleeve.value
        && this.macStatus !== Consts.MACWeightingStatus.Portfolio.value) {
      this.disableSearch = true;
      this.selectedSecurity = undefined;
      // eslint-disable-next-line eqeqeq
      this.hideSearchAndGrid = (this.weightingModel && this.weightingModel.isEdit == true) ? true : false; // While Editing, for 'Model' & 'No' option we will hide weightings table.
    } else {
      this.disableSearch = false;
      this.hideSearchAndGrid = false;
    }
    this.parentCallback.emit({ action: 'statusChange', value: this.macStatus }); // to update text on panel header
  }
  updateStatusText() {
    return Utils.getMACStatusTextById(this.macStatus, this.weightingModel.type);
  }

  isMacDetailsChanged() {
    let isChanged = false;
    // Check whether any changes made in weightings
    // eslint-disable-next-line eqeqeq
    if (this.initialMacStatus != this.macStatus) {
      isChanged = true;
    }

    if (!isChanged && this.securitiesGridData) {
      this.securitiesGridMasterData.forEach(record => {
        // eslint-disable-next-line eqeqeq
        if (record.action == Consts.MACEditActions.Add || record.action == Consts.MACEditActions.Update || record.action == Consts.MACEditActions.Delete) {
          isChanged = true;
          return;
        }
      });
    }
    return isChanged;
  }
  isValidTaxablesSelected() {
    // eslint-disable-next-line eqeqeq
    if (this.macStatus == Consts.MACWeightingStatus.Yes.value) {
      this.taxableErrorMsg = undefined;
      let isValidTaxables = true;
      this.securitiesGridData.forEach(function (item) {
        if (+item.taxableSecurity === 0 && +item.taxDeferredSecurity === 0 && +item.taxExemptSecurity === 0) {
          isValidTaxables = false;
          return;
        }
      });
      if (isValidTaxables) {
        return true;
      } else {
        this.taxableErrorMsg = 'At least one of the T/TD/TE should be set to ‘Yes’ for each row.';
        return false;
      }
    } else {
      return true;
    }
  }
  validateAndSaveMACWeightings(validationObj, isEdit = false) {
    // this.taxableErrorMsg = undefined;
    this.macWeightingValidationComponent.validateAndSaveMACWeightings(validationObj, isEdit);
  }
  saveMACWeightings() {
    this.macWeightingValidationComponent.saveMACWeightings(); // Assign or Update
  }


  getWeightingStatusList(): void {
    this.macWeightingStatusList = [];
    if (this.weightingModel.type === Consts.MacEntityTypes.Model) {
      this.macWeightingStatusList.push({ id: Consts.MACWeightingStatus.Yes.value, name: Consts.MACWeightingStatus.Yes.text });
      this.macWeightingStatusList.push({ id: Consts.MACWeightingStatus.No.value, name: Consts.MACWeightingStatus.No.text });
    }

    if (this.weightingModel.type === Consts.MacEntityTypes.Sleeve) {
      this.macWeightingStatusList.push({ id: Consts.MACWeightingStatus.None.value, name: Consts.MACWeightingStatus.None.text });
      this.macWeightingStatusList.push({ id: Consts.MACWeightingStatus.Sleeve.value, name: Consts.MACWeightingStatus.Sleeve.text });
      this.macWeightingStatusList.push({ id: Consts.MACWeightingStatus.Model.value, name: Consts.MACWeightingStatus.Model.text });
    }

    if (this.weightingModel.type === Consts.MacEntityTypes.Portfolio) {
      this.macWeightingStatusList.push({ id: Consts.MACWeightingStatus.None.value, name: Consts.MACWeightingStatus.None.text });
      this.macWeightingStatusList.push({ id: Consts.MACWeightingStatus.Portfolio.value, name: Consts.MACWeightingStatus.Portfolio.text });
      this.macWeightingStatusList.push({ id: Consts.MACWeightingStatus.Model.value, name: Consts.MACWeightingStatus.Model.text });
    }
  }

  private getMacModelValidationFeatureFlag(): void {
    this._splitIoService.flagEnabled$('mac_model_validation_F234673').subscribe(
      (enabled: boolean) => {
        this.isMacModelValidationFFEnabled = enabled;
      }
    );
  }
}
