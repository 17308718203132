import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError, defer } from 'rxjs';
import { catchError, finalize, share, tap } from 'rxjs/operators';
import { SessionHelper } from './session.helper';
import { ILogin } from '../login/login';
import { CustomSubService, ErrorNotificationService } from './customSubService';
import { NotificationService as AppNotificationService } from './customSubService';
import { v1 as UUID } from 'uuid';
import { IRequestBasicOptions, IRequestOptions } from './interfaces';
import { api } from '../../environments/api';
import { development } from '../../environments/development';

@Injectable()
export class OEHttpClient {
  private _accessTokenName = 'accessTokenInfo';

  _subService: CustomSubService;
  _errorNotificationService: ErrorNotificationService;

  constructor(
    private _http: HttpClient,
    private _sessionHelper: SessionHelper,
    subService: CustomSubService,
    errorNotificationService: ErrorNotificationService,
    private _appNotificationService: AppNotificationService,
    @Inject('HOST_ORIGIN_NAME') private _hostOriginName: string) {
    this._subService = subService;
    this._errorNotificationService = errorNotificationService;
  }

  /** Creating authorization headers for an Eclipse endpoint */
  createAuthorizationHeader(): HttpHeaders {
    const sessionToken = this.getSessionToken('eclipse_access_token');

    let authHeaders = this.getBasicHttpHeaders(sessionToken);

    authHeaders = authHeaders.append('x-requestId', UUID());
    return authHeaders;
  }

  /**
   * Creates and returns headers for the purpose of calling an Orion Connect endpoint.  `Authorization`
   * header is set to the given Orion Connect session {@link token}.
   * @param token The session token to be used in the `Authorization` header.
   * @returns HTTP headers, including `Accept`, `Content-Type` and `Authorization`.
   */
  createOrionConnectAuthorizationHeader(token: string): HttpHeaders {
    if (!token) {
      console.log('Cannot create an Orion Connect Authorization HTTP Header because no session token was provided.');
      // We don't return an error -- allowing the endpoint to return an HTTP 401/Unauthorized for logging.
    }

    return this.getBasicHttpHeaders(token);
  }

  /**
   * Returns the session token for the service as determined by the given {@link accessTokenName}.
   * @param accessTokenName The name of the access token to be used, such as `eclipse_access_token` or
   * `orion_access_token`.
   * @returns the session token for the service determined by the given {@link accessTokenName}, or null if the
   * token could not be obtained.
   */
  private getSessionToken(accessTokenName: string): string {
    const accessToken = this._sessionHelper.get<ILogin>(this._accessTokenName);

    if (!accessToken) {
      console.log('While creating an HTTP Header, the access token could not be obtained.');
      return null;
    }

    const sessionId = accessTokenName === 'eclipse_access_token'
      ? accessToken.eclipse_access_token
      : accessToken.orion_access_token;

      if (!sessionId) {
        console.log(`${accessTokenName} not found.  Access token information: `, accessToken);
        return null;
      }

      return sessionId;
  }


  /**
   * Creates HTTP Headers for `Accept` and `Content-Type` with values of 'application/json', and an optional
   * `Authorization` header with the given {@link token}.
   * @param token The session token to be used with the optional `Authorization` header.  If not provided, no
   * `Authorization` header is created.
   * @returns HTTP headers, including `Accept`, `Content-Type` and optionally, `Authorization`.
   */
  private getBasicHttpHeaders(token: string = null): HttpHeaders {
    let authHeaders = new HttpHeaders();
    authHeaders = authHeaders.set('Accept', 'application/json');
    authHeaders = authHeaders.append('Content-Type', 'application/json');

    if (token) {
      authHeaders = authHeaders.append('Authorization', 'Session ' + token);
    }

    return authHeaders;
  }

  /*** append host url to given path and returns  */
  getApiUrl(route: string): string {
    let apiEndpoint: string;

    // get the site domain as the base url for api requests. local builds ignore this since v1/v2 don't run on the same domain:port
    // and have those values specified in environment.
    const host: string = development.local ? '' : this._hostOriginName;

    // if the version is included as the first url segment, look up the host URL for that version in the config
    const routeParts = route.split('/');
    if (routeParts.length > 0) {
      const apiVersion = routeParts[0]; // get the first segment of the route
      const apiVersionUrl = api.apiEndpoint[apiVersion];
      if (apiVersionUrl) {
        apiEndpoint = apiVersionUrl.replace(`${apiVersion}/`, ''); // remove the version from the host URL (it's already included in the route)
      }
    }

    if (!apiEndpoint) { // default to v1
      apiEndpoint = route.startsWith('v1/') ? api.apiEndpoint.v1.replace('v1/', '') : api.apiEndpoint.v1;
    }
    return `${host}${apiEndpoint}${route}`;
  }

  request(method: string, url: string, options: IRequestOptions | { [option: string]: any } = {}) {
    options.headers = options.headers || this.createAuthorizationHeader();
    let request = this._http.request(method, this.getApiUrl(url), options);

    if (options.showError !== false) {
      request = request.pipe(catchError((err: any) => {
        const requestId = options.headers.get('x-requestId');
        /** Getting x-requestId from auth headers to displaying in Global Exception Popup */
        // eslint-disable-next-line eqeqeq
        err.requestId = requestId != undefined ? requestId : '';
        this.errorNotificationEmit(err);
        return throwError(err);
      }));
    }

    return this.intercept<any>(request, url, options.headers.get('x-requestId'), options.showProgress !== false);
  }

  /** To process get request */
  getData(url: string, showProgress?: boolean) {
    return this.get<any>(url, { showProgress: showProgress });
  }

  /**
   * Gets an image/blob.  Treats response as a raw file, so no parsing is performed.
   * @param url
   */
  getImage(url: string): Observable<any> {
    const authHeaders = this.createAuthorizationHeader();
    return this._http.get(this.getApiUrl(url), {headers: authHeaders, responseType: 'blob'})
      .pipe(catchError((err: any) => {
        const requestId = authHeaders.get('x-requestId');
        /** Getting x-requestId from auth headers to displaying in Global Exception Popup */
        err.requestId = requestId !== undefined ? requestId : '';
        this.errorNotificationEmit(err);
        return throwError(err);
      }));
  }

  /**
   * Constructs a GET request and returns the response
   * @param url The endpoint URL
   * @param options Options
   */
  get<T>(url: string, options?: IRequestBasicOptions): Observable<T> {
    const authHeaders = this.createAuthorizationHeader();
    const g = this._http.get<T>(this.getApiUrl(url), { headers: authHeaders }).pipe(catchError((err: any) => {
      const requestId = authHeaders.get('x-requestId');
      /** Getting x-requestId from auth headers to displaying in Global Exception Popup */
      err.requestId = requestId !== undefined ? requestId : '';
      if(options?.showError === undefined || options?.showError) {
        this.errorNotificationEmit(err);
      }
      return throwError(err);
    }));
    return this.intercept<T>(g, url, authHeaders.get('x-requestId'), options?.showProgress ?? true);
  }

  login(url: string, data: HttpHeaders) {
    const authHeaders = data;
    return this.intercept<any>(this._http.get(this.getApiUrl(url), { headers: authHeaders }), url, UUID());
  }

  /** To process post request */
  // TODO: The showProgress logic is hard to understand.
  postData(url: string, data, showProgress?: boolean) {
    const authHeaders = this.createAuthorizationHeader();

    const pd = this._http.post(this.getApiUrl(url), JSON.stringify(data), {
      headers: authHeaders,
    }).pipe(catchError((err: any) => {
      const requestId = authHeaders.get('x-requestId');
      /** Getting x-requestId from auth headers to displaying in Global Exception Popup */
      // eslint-disable-next-line eqeqeq
      err.requestId = requestId != undefined ? requestId : '';
      this.errorNotificationEmit(err);
      return throwError(err);
    }));

    // eslint-disable-next-line eqeqeq
    return this.intercept<any>(pd, url, authHeaders.get('x-requestId'), showProgress == undefined ? true : showProgress);
  }

  /**
   * Sends the given data to the given Orion Connect URI via an HTTP POST.
   * @param uri The Orion Connect URI to send the POST to.
   * @param token The session token to be added to the `Authorization` header for accessing the Orion Connect
   * endpoint.
   * @param data The data that gets stringified as JSON and sent as the payload.
   * @param showProgress
   * @returns {Observable<any>}
   */
  postDataToOrionConnectUri(uri: string, token: string, data, showProgress?: boolean): Observable<any> {
    const authHeaders = this.createOrionConnectAuthorizationHeader(token);

    const pd = this._http.post(uri, JSON.stringify(data), {
      headers: authHeaders,
    }).pipe(catchError((err: any) => {
      const requestId = authHeaders.get('x-requestId');
      /** Getting x-requestId from auth headers to displaying in Global Exception Popup */
      err.requestId = requestId ?? '';
      this.errorNotificationEmit(err);
      return throwError(() => err);
    }));

    // TODO: The showProgress logic is hard to understand.
    return this.intercept<any>(pd, uri, authHeaders.get('x-requestId'), showProgress ?? true);
  }

  /** To process post request */
  downloadFile(url: string, data: any, showProgress?: boolean) {
    const authHeaders = this.createAuthorizationHeader();
    const df = this._http.post(this.getApiUrl(url), JSON.stringify(data), {
      headers: authHeaders, responseType: 'blob', observe: 'body'
    }).pipe(catchError((err: any) => {
      // response was a blob, so we need to read it as a file in order to extract the error message
      const reader: FileReader = new FileReader();
      /** Getting x-requestId from auth headers to displaying in Global Exception Popup */
      const requestId = authHeaders.get('x-requestId');
      err.requestId = requestId !== undefined ? requestId : '';

      const obs = Observable.create((observer: any) => {
        reader.onloadend = (e) => {
          err.message = JSON.parse(reader.result as string);
          this.errorNotificationEmit(err);
          observer.error(err);
          observer.complete();
        };
      });
      reader.readAsText(err.error);
      return obs;
    }));
    // eslint-disable-next-line eqeqeq
    return this.intercept<any>(df, url, authHeaders.get('x-requestId'), showProgress == undefined ? true : showProgress);
  }

  /** This is a version of the downloadFile method
   * that returns the entire response along with the body/blob
   * We can use the headers to get the filename from content-disposition
  */
  downloadFileWithHeadersFromTrustedSource(url: string, data: any, showProgress?: boolean) {
    const authHeaders = this.createAuthorizationHeader();
    const df = this._http.post(this.getApiUrl(url), JSON.stringify(data), {
      headers: authHeaders, responseType: 'blob', observe: 'response'
    }).pipe(catchError((err: any) => {
      // response was a blob, so we need to read it as a file in order to extract the error message
      const reader: FileReader = new FileReader();
      /** Getting x-requestId from auth headers to displaying in Global Exception Popup */
      const requestId = authHeaders.get('x-requestId');
      err.requestId = requestId !== undefined ? requestId : '';

      const obs = Observable.create((observer: any) => {
        reader.onloadend = (e) => {
          err.message = JSON.parse(reader.result as string);
          this.errorNotificationEmit(err);
          observer.error(err);
          observer.complete();
        };
      });
      reader.readAsText(err.error);
      return obs;
    }));
    // eslint-disable-next-line eqeqeq
    return this.intercept<any>(df, url, authHeaders.get('x-requestId'), showProgress == undefined ? true : showProgress);
  }

  /** To process update request */
  updateData(url: string, data, showProgress?: boolean) {
    const authHeaders = this.createAuthorizationHeader();
    const ud = this._http.put(this.getApiUrl(url), JSON.stringify(data), {
      headers: authHeaders
    }).pipe(catchError((err: any) => {
      const requestId = authHeaders.get('x-requestId');
      /** Getting x-requestId from auth headers to displaying in Global Exception Popup */
      // eslint-disable-next-line eqeqeq
      err.requestId = requestId != undefined ? requestId : '';
      this.errorNotificationEmit(err);
      return throwError(err);
    }));
    // eslint-disable-next-line eqeqeq
    return this.intercept<any>(ud, url, authHeaders.get('x-requestId'), showProgress == undefined ? true : showProgress);
  }

  /** To process patch request */
  patchData(url: string, data, showProgress?: boolean) {
    const authHeaders = this.createAuthorizationHeader();
    const ptd = this._http.patch(this.getApiUrl(url), data, {
      headers: authHeaders
    }).pipe(catchError((err: any) => {
      const requestId = authHeaders.get('x-requestId');
      /** Getting x-requestId from auth headers to displaying in Global Exception Popup */
      // eslint-disable-next-line eqeqeq
      err.requestId = requestId != undefined ? requestId : '';
      this.errorNotificationEmit(err);
      return throwError(err);
    }));

    // eslint-disable-next-line eqeqeq
    return this.intercept<any>(ptd, url, authHeaders.get('x-requestId'), showProgress == undefined ? true : showProgress);
  }

  /** To process delete request */
  deleteData(url: string, data?: any, showProgress?: boolean) {
    const authHeaders = this.createAuthorizationHeader();
    const options: {
      body?: any;
      headers?: HttpHeaders | {
        [header: string]: string | string[];
      };
      observe?: any; // have to use `any` because Angular doesn't export HttpObserve
    } = {
      headers: authHeaders,
      observe: 'body' // new change
    };

    /**
     * DELETE requests should not contain a payload.  Any endpoint receiving a payload should be evaluated to conform with W3C standards.
     * From RFC 8231 https://tools.ietf.org/html/rfc7231#section-4.3.5
     *   A payload within a DELETE request message has no defined semantics; sending a payload body on a DELETE request might cause some existing implementations to reject the request.
     */
    if (data) {
      options.body = data;
    }

    const o = this._http.request('delete', this.getApiUrl(url), options).pipe(catchError((err: any) => {
      const requestId = authHeaders.get('x-requestId');
      /** Getting x-requestId from auth headers to displaying in Global Exception Popup */
      // eslint-disable-next-line eqeqeq
      err.requestId = requestId != undefined ? requestId : '';
      this.errorNotificationEmit(err);
      return throwError(err);
    }));

    // eslint-disable-next-line eqeqeq
    return this.intercept<any>(o, url, authHeaders.get('x-requestId'), showProgress == undefined ? true : showProgress);
  }

  /**
   * Intercepts http requests and posts them to the custom sub service so they can be tracked.
   * @param observable
   * @param url
   * @param uuid
   * @param showProgress
   */
  intercept<T>(observable: Observable<T>, url, uuid, showProgress: boolean = true): Observable<T> {
    if (!showProgress) {
      return observable;
    }
    return defer(() => {
      this._subService.beforeQueueRequest.emit({ url: url, uuid: uuid });
      return observable.pipe(
        finalize(() => {
          this._subService.afterQueueRequest.emit({ url: url, uuid: uuid });
        }));
    }).pipe(share());
  }

  errorNotificationEmit(error: any) {
    this._errorNotificationService.errorResponse.emit(error);
  }

  /** Processing file uploading  */
  uploadFile(url: string, formData: FormData): Observable<any> {
    const self = this;
    const uuid = UUID();
    return Observable.create(observer => {
      const accessToken = <ILogin>this._sessionHelper.getAccessToken(this._accessTokenName);
      let percentComplete = 0;
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = function () {
        // eslint-disable-next-line eqeqeq
        if (xhr.readyState == 4) {
          // eslint-disable-next-line eqeqeq
          if (xhr.status == 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.open('POST', this.getApiUrl(url), true);
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.setRequestHeader('Authorization', `Session ${accessToken.eclipse_access_token}`);
      xhr.setRequestHeader('x-requestId', uuid);
      xhr.upload.addEventListener('progress', function (e) {
        percentComplete = Math.round((e.loaded / e.total) * 40);
        self._appNotificationService.fileImportProgress.emit({ percentage: percentComplete });
      }, false);

      this._subService.beforeQueueRequest.emit({ url: url, uuid: uuid });
      xhr.send(formData);
    }).pipe(tap(() => {
      this._subService.afterQueueRequest.emit({ url: url, uuid: uuid });
    }),
      catchError((err) => {
        this._subService.afterQueueRequest.emit({ url: url, uuid: uuid });
        return throwError(err); // rethrow the error
      }));
  }
}
